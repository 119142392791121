import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AngularFireAuthGuard,redirectUnauthorizedTo,redirectLoggedInTo,} from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['welcome']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['']);



const routes: Routes = [{ path: 'welcome', loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule) },
 { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) ,
 canActivate: [AngularFireAuthGuard],data: { authGuardPipe: redirectLoggedInToHome }}, 
 { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
 { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
 canActivate: [AngularFireAuthGuard],
 data: { authGuardPipe: redirectUnauthorizedToLogin } },
 { path: 'details', loadChildren: () => import('./details/details.module').then(m => m.DetailsModule),
 canActivate: [AngularFireAuthGuard],
 data: { authGuardPipe: redirectUnauthorizedToLogin } }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
