// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig :{
   apiKey: "AIzaSyCWAWKbBOqh1OY57xf0z_COkDS0yx-cf4E",
   authDomain: "ktuassist-beb07.firebaseapp.com",
   projectId: "ktuassist-beb07",
   storageBucket: "ktuassist-beb07.appspot.com",
   messagingSenderId: "938168044292",
   appId: "1:938168044292:web:a38d44f43a33ace3aede51",
   measurementId: "G-1V5RCSTZPS"
 }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
