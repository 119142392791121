import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  @Input() tasktype: any;
  @Input() data: any;
  @Input() message:any;
  constructor(protected dialogref: NbDialogRef<ConfirmComponent>,
    private toastService: NbToastrService,
    private route: Router) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogref.close(false);
  }


  Action(tasktype:any,data:any) {

    switch (tasktype) {
      case 'logout':
        this.dialogref.close(true);
        break;
     
        default:
          break;
    }

}

  }